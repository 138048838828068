import React from 'react';
import { Link, graphql, PageProps } from 'gatsby';

import Bio from './bio';
import Layout from '../layout/layout';
import SEO from '../shared/seo';
import Button from '../shared/button';
import SearchPosts from './searchPosts';

type Props = PageProps & {
  data: any;
};

class Blog extends React.Component<Props> {
  render() {
    const { data, navigate, location } = this.props;
    const siteTitle = data.site.siteMetadata.title;
    const posts = data.allMdx.edges;
    const localSearchBlog = data.localSearchBlog;

    return (
      <Layout>
        <SEO title="All posts" />
        <Bio />
        <SearchPosts
          posts={posts}
          localSearchBlog={localSearchBlog}
          navigate={navigate}
          location={location}
        />
      </Layout>
    );
  }
}

export default Blog;
