/**
 * Bio component that queries for data
 * with Gatsby's StaticQuery component
 *
 * See: https://www.gatsbyjs.org/docs/static-query/
 */

import React from 'react';
import { graphql } from 'gatsby';
import styled from 'styled-components';

function Bio() {
  return <></>;
  // return (
  //   <StaticQuery
  //     query={bioQuery}
  //     render={data => {
  //       const { author, social } = data.site.siteMetadata
  //       return (
  //         <Container>
  //           <Image
  //             fixed={data.avatar.childImageSharp.fixed}
  //             alt={author}
  //             style={{
  //               marginRight: rhythm(1 / 2),
  //               marginBottom: 0,
  //               minWidth: 50,
  //               borderRadius: `100%`,
  //             }}
  //             imgStyle={{
  //               borderRadius: `50%`,
  //             }}
  //           />
  //         </Container>
  //       )
  //     }}
  //   />
  // )
}

const bioQuery = graphql`query BioQuery {
  avatar: file(absolutePath: {regex: "/icon.png/"}) {
    childImageSharp {
      gatsbyImageData(width: 50, height: 50, layout: FIXED)
    }
  }
  site {
    siteMetadata {
      author
      social {
        twitter
      }
    }
  }
}
`;

const Container = styled.div`
  display: flex;
`;

export default Bio;
