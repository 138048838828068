import React from 'react';
import { Link, graphql } from 'gatsby';
import BlogLayout from '../components/blog/blog';

class Blog extends React.Component {
  render() {
    return <BlogLayout {...this.props} />;
  }
}

export default Blog;

export const pageQuery = graphql`
  query {
    site {
      siteMetadata {
        title
      }
    }
    localSearchBlog {
      index
      store
    }
    allMdx(sort: { fields: [frontmatter___date], order: DESC }) {
      edges {
        node {
          excerpt
          fields {
            slug
          }
          frontmatter {
            date(formatString: "MMMM DD, YYYY")
            title
            description
          }
        }
      }
    }
  }
`;
